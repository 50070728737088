export default {
  landing: {
    subscribeForm: {
      title: 'Subscribe to the newsletter',
      subtitle: 'Stay up to date',
      subscribe: 'Subscribe',
      subscribed: 'Subscribed!',
      placeholder: 'Enter your email',
      error: 'There was an error. Try again later.',
    },
    demoDayCompanyList: {
      description: 'These are the startups that will participate in the Platanus Demo Day.',
      subtitle: 'Meet the',
      title: 'Startups',
      viewProfile: 'Go to profile',
    },
  },
  here: 'here',
  general: {
    founders: 'Founders',
    founder: 'Founder',
  },
  genericError: 'There was an error. Try again later.',
  form: {
    loading: 'Loading',
    yes: 'Yes',
    no: 'No',
    submit: 'Submit',
    required: 'Required',
    confirm: 'Confirm',
    cancel: 'Cancel',
    file: {
      upload: 'Upload',
      orDrag: 'or drag your file',
      upTo: 'Up to',
    },
    accept: 'Accept',
    optional: 'Optional',
    close: 'Close',
    continue: 'Continue',
    phone: {
      validating: 'validating...',
      invalid: {
        warning: 'We could not validate your phone number.',
        tooltip: 'This phone number may not be able to receive Whatsapp messages. ' +
                      'Make sure you enter a valid Whatsapp number.',
      },
      valid: 'Phone number valid.',
    },
  },
  layout: {
    navbar: {
      logOut: 'Log out',
      editProfile: 'Edit profile',
    },
  },
  user: {
    form: {
      firstName: 'First name',
      lastName: 'Last name',
      linkedin: 'Linkedin url',
      country: 'Country',
    },
    demoDayBadge: {
      angel: 'Angel',
    },
  },
  people: {
    inactive: 'Inactive',
    goToProfile: 'Go to profile',
    contact: 'Contact',
  },
  filters: 'Filters',
  list: {
    filters: {
      batch: 'Batch',
      company: 'Company',
      investedCompany: 'Companies invested in',
      investorType: 'Investor type',
      investmentStage: 'Investment stage',
      leadRounds: "Lead's rounds",
      investmentTicket: 'Investment tickets',
      country: 'Country',
      rating: 'Rating',
      industry: 'Industry',
      tag: 'Tags',
      activeStatus: 'Status',
      kind: 'Kind of content',
      category: 'Category',
      position: 'Position',
      formatOfOperation: 'Format of operation',
      activeStatuses: {
        true: 'Active',
        false: 'Inactive',
      },
    },
    search: 'Search...',
    cleanAllFilters: 'Reset filters',
    applyFilters: 'Apply filters',
    noResults: 'No results',
    noResultsDescription: 'Try changing your filters or search.',
  },
  comments: {
    title: 'Comments',
    noComments: 'There are no comments.',
  },
  demoDay: {
    application: {
      basicInfo: 'Basic information',
      fund: {
        title: 'Fund',
      },
      others: {
        title: 'Others',
      },
      communityApplication: 'Steps before investing',
      acceptRules: "I've read, understood and accepted Demo Day participation rules and definitions.",
      qualifiedInvestor: 'I have the status of Qualified Investor in accordance with general' +
                          'rule No. 336 of the CMF (it is not a requirement to participate).',
      accreditedInvestor: 'I have the quality of Accredited Investor in accordance with Rule 501' +
                            'of Regulation D on the Securities Act of the year 1933 of the USA' +
                            '(it is not a requirement to participate).',
      rules: 'Rules',
      success: {
        title: '¡Done!',
        description: "You can proceed to invest in this batch's startups.",
      },
      fastApplicationDescription: "Before you can invest in this DemoDay's startups, " +
                                    'we need to ask a few questions and make sure you accept our rules.',
      applicationStep: 'Basic information',
      acceptRulesStep: 'Accept rules',
      regions: {
        asia: 'Asia',
        europe: 'Europe',
        africa: 'Africa',
        usaCanada: 'USA & Canada',
        latam: 'Latinamerica',
        oceania: 'Oceania',
      },
    },
    company: {
      schedule: 'Schedule',
      keyPoints: 'Key points',
      like: 'Like',
      liked: 'Liked',
      pitch: 'Pitch',
    },
    rules: {
      toggle: 'See all rules',
    },
    streaming: {
      attendees: 'Attendees',
      connections: 'Connections',
      fullscreen: 'Fullscreen',
    },
    virtualRound: {
      title: 'Virtual round',
      invest: 'Invest',
      scheduleOrConnect: 'Schedule or connect',
      scheduleMeeting: 'Schedule meeting 📆',
      connectWithFounders: 'Connect by email 📨',
      connectExplanation: `We will send you and the founders an email so that you can continue the
        conversation there.`,
      roundInfo: 'Round information',
      companyInfo: 'Startup information',
      batch: 'Batch {season}',
      someoneInvested: '🤑 Someone just invested.',
      someoneScheduledMeeting: '📅 Someone scheduled a meeting.',
      someoneConnected: '📨 Someone connected with a startup.',
      investIn: 'Invest in',
      auctionOfferInput: 'Enter your offer (USD)',
      improveAuctionOfferInput: 'Enter your new offer (USD)',
      yourOffer: 'Your offer',
      yourOfferDetails: 'Your offer details',
      existingOfferDetails: 'Your previous offer details',
      willMakeOffer: 'You will make an offer with the following details.',
      everythingCorrect: 'Is everything ok?',
      yourLastOffer: 'Your last offer',
      selectSchedulingMotive: 'Select your purpose',
      otherMotive: 'Other purpose',
      schedulingMotives: {
        invest: 'To invest',
        client: 'To be a client',
        partnership: 'To discuss a partnership',
        other: 'For other purpose',
      },
      orderCreatedSuccess: {
        title: 'Your offer was created successfully.',
        body: `After the virtual rounds are closed, if your offer has been selected, the startup will contact you
              by e-mail with the next steps. In the meantime, you can complete your investment legal information.`,
        accept: 'Complete now',
        close: 'Later',
      },
      auction: {
        size: 'Round size',
        sizeTooltip: `The total amount of money that the round is looking for. It is the sum of money raised in the
                      weeks leading up to the Demo Day and the money the company wants to raise during the Demo Day.`,
        timeLeft: 'Time left',
        startsIn: 'Starts in',
        hasNotStarted: 'The auction has not started yet.',
        days: 'D',
        hours: 'H',
        makeOffer: 'Make offer',
        improveOffer: 'Improve offer',
        confirmOffer: 'Yes, make offer',
        completelyIn: 'Your offer is being <b>SELECTED</b>.',
        partiallyIn: 'Your offer is being <b>PARTIALLY</b> selected.',
        out: 'Your offer is <b>NOT</b> being selected.',
        wantToKnowWhy: 'If you want to understand why, please reach out to through our chat.',
        rememberToFillLegalInformation: 'Remember to complete your legal investment information',
        uncapped: 'Uncapped',
        minOrderAmount: 'The minimum amount is US {amount}.',
        finished: 'The auction has finished.',
        previouslyRaised: 'Previously raised',
        virtualRoundAmount: 'Virtual Round amount',
        minAmount: 'Minimum ticket',
      },
      goToStreaming: 'Switch to streaming',
    },
    investmentLegalInformation: {
      investmentData: 'Investment information',
      selectAnyTwoOptions: 'Select one of the two options',
      investmentType: {
        legalPerson: 'Legal person',
        naturalPerson: 'Natural person',
      },
      previousInformation: 'Previous legal information',
      addNewInformation: 'Add new information',
      form: {
        personType: {
          legal: 'Legal',
          natural: 'Natural',
        },
        legal: {
          businessName: 'Legal entity name',
          legalPersonRut: 'Legal entity ID number',
          legalRepresentativeRut: 'Legal representative ID number',
          legalRepresentativeName: 'Legal representative name',
          legalRepresentativeEmail: 'Legal representative email',
          legalPersonAddress: 'Legal entity address',
        },
        natural: {
          naturalPersonName: 'Full name',
          naturalPersonRut: 'ID number',
          naturalPersonAddress: 'Address',
        },
      },
    },
    presentationsFinished: {
      message: 'The presentations are over.',
      goToRewatch: 'You can now watch the recorded pitches.',
    },
    connections: {
      connect: 'Connect',
      connectNotification: 'Connected with %{companyName}! Check your email.',
      alreadyConnected: 'Already connected',
      investorForm: {
        title: 'Enter your investor information',
        investorName: 'Full name',
        investorCompany: 'Company',
        investorCompanyDescription: 'Leave blank if you are an angel investor.',
        investorPhone: 'Phone',
        submit: 'Go to Platanus Connect',
      },
    },
  },
  applicationProcess: {
    apply: 'Apply',
    programDates: {
      earlyApplicationProcess: {
        title: 'Early application',
        description: `The deadline for early application is <strong>{date}</strong> of
                      <strong>Chile</strong>. If you apply in the early application period,
                      we will interview you earlier and keep you in mind for a longer time.`,
      },
      generalApplicationProcess: {
        title: 'General application',
        description: 'The deadline for general application is <strong>{date}</strong> of <strong>Chile</strong>.',
      },
      lateApplicationProcess: {
        title: 'Late application',
        description: `The last date to apply is <strong>{date}</strong> of <strong>Chile</strong>. If you apply in
                      this period, we cannot guarantee a response to your application.`,
      },
      results: {
        title: 'Results',
        description: 'The date you will know if you advance in the process depends on the period in which you apply.',
        early: 'Early application: no later than {date}',
        general: 'General application: no later than {date}',
        late: 'Late application: no later than {date}',
      },
      program: {
        title: 'Program',
        description: `The program lasts 3 months, between <strong>{startDate}</strong> and
                      <strong>{endDate}</strong>.`,
      },
    },
  },
  companyStatus: {
    sections: {
      weeklyReports: 'Weekly reports',
      groupMeetings: 'Group meetings',
      investorUpdates: 'Investor updates',
    },
    investorUpdate: {
      rowTitle: 'Update {date}',
      body: 'Message',
      metricLabel: 'Metric',
      metric: 'Value',
      asks: 'Asks (request to the investors)',
      new: 'New update',
      createSuccess: 'Update created!',
      addMetric: 'Add Metric',
      otherMetricLabel: 'Name',
    },
  },
  actions: {
    show: 'Show',
    edit: 'Edit',
    applications: 'Applications',
    cancel: 'Cancel',
    send: 'Send',
    delete: 'Delete',
  },
  libraryItems: {
    listTitle: 'Platanus resource library',
    listSubtitle: 'Here you will find information we have found and created over time.',
    copyLink: 'Copy link',
  },
  officeHours: {
    copyLink: 'Copy link',
  },
  jobOffers: {
    listTitle: {
      default: 'Work offers',
      owner: 'Your work offers',
    },
    listSubtitle: {
      default: 'These are the open positions in Platanus startups.',
      owner: 'These are your open positions',
    },
    applications: '{count} applications | {count} application | {count} application',
    new: 'Add offer',
    newTitle: 'Add new offer',
    editTitle: 'Edit offer',
    newSubmit: 'Create offer',
    editSubmit: 'Save offer',
    active: 'Active',
    countryInformation: 'Country',
    wageRange: 'Salary range',
    formatOfOperation: 'Operation format',
    position: 'Position name',
    aboutUs: 'About us',
    benefits: 'Benefits',
    description: 'Description',
    requiredSkills: 'Required skills',
    otherOffers: 'Other offers from {companyName}',
    apply: 'Apply',
    applied: 'Applied',
    closed: 'Offer Closed',
    formatOfOperations: {
      presential: 'Presential',
      remote: 'Remote',
      hybrid: 'Hybrid',
    },
    deleteModal: {
      confirmationMessage: 'Are you sure you want to eliminate this Job offer?',
      explanationMessage: `If you confirm this action you would not be able to access this
        information again`,
      confirmationAction: "Yes, I'm sure",
    },
    actions: {
      show: 'Show',
      edit: 'Edit',
      applications: 'Applications',
      cancel: 'Cancel',
      send: 'Send',
      delete: 'Delete',
    },
    application: {
      title: 'Application',
      resume: 'Resume',
      aboutYou: 'Tell us about yourself',
      successfullySent: 'Your application has been successfully sent',
    },
  },
  jobApplications: {
    titles: {
      default: 'Applications',
      owner: 'Applications to your job offers',
      withPosition: 'Application for %{position}',
    },
    subTitles: {
      owner: 'These are the applications you have received',
    },
    name: 'Name',
    email: 'Email',
    purpose: 'Motivation',
    actions: {
      show: 'Show',
      downloadCV: 'Download CV',
    },
  },
  perks: {
    suggestions: {
      do: 'Suggest a perk',
      notFoundQuestion: "You didn't found the perk you need?",
      successfullySent: 'Perk suggestion sent succesfully',
      nameReference: "Reference name (Example: 'Heroku dynos discount')",
      description: 'Describe the perk in detail',
    },
    showBenefits: 'Show Benefits',
    hideBenefits: 'Hide Benefits',
    benefits: 'Benefits',
    use: 'Use perk',
    redeem: 'Redeem perk',
    redeemInstructions: 'Follow the following instructions to redeem this perk:',
    fileUrl: 'File associated:',
    download: 'Download',
  },
  investors: {
    listTitle: 'Investors',
    kind: {
      vcFund: 'Venture Capital Firm',
      familyOffice: 'Family Office',
      angel: 'Angel Investor',
      accelerator: 'Accelerator',
      cvc: 'CVC',
      syndicate: 'Syndicate',
    },
    stages: {
      preSeed: 'Pre-seed',
      seed: 'Seed',
      seriesA: 'Series A',
      seriesB: 'Series B',
      seriesC: 'Series C',
      beyondSeriesC: 'Series D+',
    },
    investments: 'investments',
    calification: 'Rate',
    noCalification: 'No rate',
    ticketsBetween: 'Tickets between US %{min} and US %{max}',
    averageTicket: 'Average ticket %{averageTicket}',
    leadRounds: 'Lead rounds',
    doNotLeadRounds: 'Does not lead rounds',
  },
  subscribers: {
    modal: {
      title: 'Subscribe to Platanus',
      blogUpdates: 'Receive updates from the Platanus Blog',
      applyUpdates: 'Receive a notification when applications begin',
      button: 'Subscribe',
      success: 'Subscription updated! 🎉',
    },
  },
};
