/* eslint-disable camelcase */
import { defineRule, configure } from 'vee-validate';
import {
  required,
  email,
  confirmed,
  min,
  max,
  min_value,
  max_value,
  ext,
  url,
  integer,
} from '@vee-validate/rules';
import isMobilePhone from 'validator/src/lib/isMobilePhone';
import { localize } from '@vee-validate/i18n';

defineRule('required', required);
defineRule('email', email);
defineRule('confirmed', confirmed);
defineRule('url', url);
defineRule('min', min);
defineRule('max', max);
defineRule('min_value', min_value);
defineRule('max_value', max_value);
defineRule('ext', ext);
defineRule('url', url);
defineRule('integer', integer);
defineRule('numeric', (value) => Number(value) || Number(value) === 0);
defineRule('multiple_of', (value, [multiple]) => (Number(value) % Number(multiple) === 0));
defineRule('phone', (value, [locale]) => {
  if (!value) return true;
  try {
    return isMobilePhone(value, locale);
  } catch {
    return isMobilePhone(value);
  }
});

configure({
  generateMessage: localize({
    es: {
      messages: {
        required: 'Campo obligatorio.',
        email: 'Debes ingresar un email válido.',
        confirmed: 'Las contraseñas no coinciden',
        ext: 'Formato de archivo inválido.',
        url: 'Debes ingresar una url válida.',
        phone: 'Debes ingresar un teléfono válido.',
        min: 'Mínimo 0:{length} caracteres',
        numeric: 'Debes ingresar un valor numérico.',
        min_value: 'Debe ser un número mayor a 0:{minValue}.',
        max_value: 'Debe ser un número menor a 0:{maxValue}.',
        multiple_of: 'Debe ser un número multiplo de 0:{multiple}',
        max: 'Deben ser máximo 0:{length} caracteres.',
        integer: 'Debes ingresar un número entero.',
      },
    },
    en: {
      messages: {
        required: 'Required field.',
        email: 'You must enter a valid email.',
        confirmed: "Passwords don't match",
        ext: 'Invalid file format.',
        url: 'You must enter a valid url.',
        phone: 'You must enter a valid phone number.',
        min: 'At least 0:{length} characters.',
        numeric: 'You must enter a numeric value.',
        min_value: 'Must be a number bigger than 0:{minValue}',
        max_value: 'Must be a number lower than 0:{maxValue}',
        multiple_of: 'Must be a multiple of 0:{multiple}',
        max: 'Maximum characters is 0:{length}.',
        integer: 'You must enter an integer.',
      },
    },
  }),
});
