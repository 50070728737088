// inject
export const groupMeetingKey = Symbol();
export const currentUserKey = Symbol();
export const currentUserCompanyKey = Symbol();
export const currentUserCompanyAuctionKey = Symbol();
export const currentUserMentoredBatchGroupKey = Symbol();
export const screenKey = Symbol();
export const flashMessagesKey = Symbol();

// event buses
export const openFrankWriterEventBusKey = Symbol();
