import { createI18n } from 'vue-i18n';
import Locales from '../../locales';

const locale = window.navigator.language.split('-')[0];

const i18n = createI18n({
  locale,
  allowComposition: true, // you need to specify that!
  fallbackLocale: 'es',
  messages: Locales.messages,
  silentTranslationWarn: true,
});

export default i18n;
