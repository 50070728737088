import axios from 'axios';
import * as humps from 'humps';

const CSRFToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
const api = axios.create({
  headers: {
    'X-CSRF-Token': CSRFToken,
  },
});

api.interceptors.request.use(config => {
  const { decamelizeKeys = true } = config;
  const newConfig = { ...config };
  if (config.params && decamelizeKeys) {
    newConfig.params = humps.decamelizeKeys(config.params);
  }
  if (config.data && decamelizeKeys) {
    newConfig.data = humps.decamelizeKeys(config.data);
  }

  return newConfig;
});

function extractCursor(response, rel) {
  const regex = new RegExp(`<[^>]*[?&]page=(\\d+)[^>]*>\\s*;\\s*rel="${rel}"`);
  const match = response.headers.link.match(regex);

  return match?.[1];
}

api.interceptors.response.use(
  response => {
    if (response.data && response.headers['content-type'].match('application/json')) {
      response.data = humps.camelizeKeys(response.data);
    }

    if (response.headers['x-page'] !== undefined) {
      response.data.currentCursor = response.headers['x-page'];
    }

    if (response.headers['x-total'] !== undefined) {
      response.data.totalCount = response.headers['x-total'];
    }

    if (response.headers['x-per-page'] !== undefined) {
      response.data.perPage = response.headers['x-per-page'];
    }

    if (response.data && response.headers.link) {
      response.data.nextCursor = extractCursor(response, 'next');
      response.data.prevCursor = extractCursor(response, 'prev');
      response.data.firstCursor = extractCursor(response, 'first');
      response.data.lastCursor = extractCursor(response, 'last');
    }

    return response;
  },
);

/*
  * quick fix to avoid attaching a new interceptor each time api() was called.
  * @TODO the proper fix would be to return the entire response object, and handle
  * the destructuring in the caller. But that requires a big refactor.
*/
function _api(args) {
  return api(args).then(response => response.data);
}

export default _api;
